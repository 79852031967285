// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-solobreak-privacy-policy-tsx": () => import("./../../../src/pages/solobreak-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-solobreak-privacy-policy-tsx" */),
  "component---src-templates-blogpost-tsx": () => import("./../../../src/templates/blogpost.tsx" /* webpackChunkName: "component---src-templates-blogpost-tsx" */)
}

